<template>

    

    <div style="padding:20px;">


        <v-card class="" elevation="" max-width="" style="margin-top:20px;" >
            <v-card-text>

                <div style="">
                    <h1 >Projetos Layouts</h1>
                </div>

                <div style="margin-top:20px;margin-left:30px;font-size:18px;">
                    <h4>Novo Ficha Light</h4>
                    <div style="margin-top:20px;padding-left:20px;">
                        
                        <div style="margin-top:20px;">
                            <div style="padding-left:20px;">
                                <p>Atualizado em 19/06/2024.</p>
                                <p>
                                    <a target="_blank" href="https://www.figma.com/design/Oa0CqRDnFhZggO9gn3THMT/Ficha-Digital-Lite?m=dev&node-id=0-1">
                                        Acessar Layout
                                    </a>
                                </p>
                            </div>
                        </div>


                    </div>
                </div>
                <hr style="margin-top:50px;margin-bottom:50px;">


                

                <div style="margin-top:20px;margin-left:30px;font-size:18px;">
                    <h4>Novo Ficha digital</h4>
                    <div style="margin-top:20px;padding-left:20px;">
                        
                        <div style="margin-top:20px;">
                            <div style="padding-left:20px;">
                                <p>Atualizado em 20/06/2024.</p>
                                <p>
                                    <a target="_blank" href="https://www.figma.com/design/i33jeCXhZTZvJPCODcWUkg/Ficha-Digital?node-id=701%3A1049&t=aVV8s8XWe420E0hq-1">
                                        Acessar Layout
                                    </a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <hr style="margin-top:50px;margin-bottom:50px;">



                <div style="margin-top:20px;margin-left:30px;font-size:18px;">
                    <h4>Check-out Express</h4>
                    <div style="margin-top:20px;padding-left:20px;">
                        
                        <div style="margin-top:20px;">
                            <div style="padding-left:20px;">
                                <p>Atualizado em 20/06/2024.</p>
                                <p>
                                    <a target="_blank" href="https://www.figma.com/design/g9efzBYXSMEBcGMd7qqJct/Check-out-Express?node-id=701%3A1049&t=C13X3DYNL5a6AEEl-1">
                                        Acessar Layout
                                    </a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <hr style="margin-top:50px;margin-bottom:50px;">





                <div style="margin-top:20px;margin-left:30px;font-size:18px;">
                    <h4>Ficha Guest</h4>
                    <div style="margin-top:20px;padding-left:20px;">
                        
                        <div style="margin-top:20px;">
                            <div style="padding-left:20px;">
                                <p>Atualizado em 20/06/2024.</p>
                                <p>
                                    <a target="_blank" href="https://www.figma.com/design/huEIlw0jxeQuAJsGbR13sF/Ficha-Guest?node-id=701%3A1049&t=VCcJ2dZMTftm2NKn-1">
                                        Acessar Layout
                                    </a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <hr style="margin-top:50px;margin-bottom:50px;">




                <div style="margin-top:20px;margin-left:30px;font-size:18px;">
                    <h4>E-spot</h4>
                    <div style="margin-top:20px;padding-left:20px;">
                        
                        <div style="margin-top:20px;">
                            <div style="padding-left:20px;">
                                <p>Atualizado em 20/06/2024.</p>
                                <p>
                                    <a target="_blank" href="https://www.figma.com/design/Av26pq19Y4VjsKoSHNQtyi/E-spot?node-id=701%3A1049&t=keWmZUSX6VU69GoK-1">
                                        Acessar Layout
                                    </a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <hr style="margin-top:50px;margin-bottom:50px;">


                <div style="margin-top:20px;margin-left:30px;font-size:18px;">
                    <h4>Web Check-in</h4>
                    <div style="margin-top:20px;padding-left:20px;">
                        
                        <div style="margin-top:20px;">
                            <div style="padding-left:20px;">
                                <p>Atualizado em 20/06/2024.</p>
                                <p>
                                    <a target="_blank" href="https://www.figma.com/design/O97FEgps2gFKnebbZT1QV7/Web-Check-in?node-id=701%3A1049&t=11Qe0tNxeRfUiVlw-1">
                                        Acessar Layout
                                    </a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <hr style="margin-top:50px;margin-bottom:50px;">




                
                






            </v-card-text>
               
        </v-card>


    </div>

    
</template>

<script>

export default {
    name:'Robos',
    components: {
    },
    data(){
        return{
            
        }
    },
    watch: {
        
    },
    methods:{
            
    },

    
    async mounted(){

    }
    
}
</script>

<style>
    .linhaFormulario{
        display: flex;
        flex-direction: row;
        /* background-color:red; */
        padding-left: 10%;
        padding-right: 10%;
    }

    .celularCampoForm{
        width:25%;
        padding:10px;
        /* background-color: green; */
    }

    .theme--light.v-input--is-disabled input, .theme--light.v-input--is-disabled textarea {
        color: rgba(0, 0, 0);
    }

</style>